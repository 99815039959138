.toolbar {
  justify-content: center;
}

.drawerMenuLinks {
  position: relative;
  display: inline-block;
  user-select: none;
  padding: 10px;
  color: #000;
  border: transparent;
  background-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
}

.toolbarMenuLinks {
  position: relative;
  display: inline-block;
  user-select: none;
  padding: 15px 20px;
  color: #fff;
  border: transparent;
  background-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
}

.toolbarMenuLinks:after {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 2px;
  background: none repeat scroll 0 0 transparent;
  background: #fff;
  bottom: 0;
  left: 50%;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}
.toolbarMenuLinks:hover:after {
  width: 100%;
  left: 0;
}

.toolbar > .active {
  transform: scale(1.3);
  transition: transform 0.5s;
}

.logo{
  max-width: 55px;
}
