.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background-color: black;
}

.aboutContainer {
  text-align: center;
  padding: 3rem 1.5rem;
}

.aboutHeader {
  font-size: 2rem;
  padding: 10px;
  color: #fff;
}

.aboutInformation {
  max-width: 800px;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 10px;
  color: #fff;
}
