.contactSection {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: black;
}

.contactContainer {
  text-align: center;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  height: fit-content;
  margin-top: -10rem;
  border-radius: 50px;
  background-color: white;
  border: 5px solid black;
}

.contactHeader {
  font-size: 2rem;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.contactForm {
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  resize: vertical;
  list-style: none;
}

input[type="text"],
input[type="email"],
textarea {
  transition: all 0.3s ease-in;
  width: 90%;
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  resize: none;
  border-radius: 4px;
  border: 1px solid black;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  box-shadow: 0 0 10px rgba(255, 128, 138, 1);
}

input[type="submit"] {
  transition: all 0.3s ease-in;
  width: 10rem;
  font-size: 1rem;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  background-color: black;
}

input[type="submit"]:hover {
  box-shadow: 0 0 10px rgba(255, 128, 138, 1);
}
