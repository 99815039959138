.card {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  max-width: 25em;
  width: 75vw;
  margin: 2em;
  border-radius: 50px;
  border: 4px solid black;
}

.card:hover {
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.githubButton:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
