.projectsSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-bottom: 17rem;
  background-color: white;
}

.projectsIntroContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 3rem 1.5rem;
  background-color: black;
}

.projectsHeader {
  font-size: 2rem;
  margin-top: 3rem;
  color: white;
}

.projectsDisclaimer {
  max-width: 800px;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  padding: 10px;
  color: #fff;
}

.projectsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1500px;
  margin-top: -120px;
}
